import { getValueWithZero } from '@/components/Model/utils/getValueWithZero';
import type { TModelData } from '@/types/modelType';

export const getMkpoClassGrandParent = (data: TModelData) => {
  const value = data.mkpoClassGrandParent?.name;
  const index = data.mkpoClassGrandParent?.classIndex || 0;
  return (value || index) ? `${getValueWithZero(index)} - ${value}` : 'НЕ ЗАДАН';
};

export const getMkpoClassParent = (data: TModelData) => {
  const value = data.mkpoClassParent?.name;
  const index = data.mkpoClassParent?.classIndex || 0;
  return (value || index) ? `${getValueWithZero(index)} - ${value}` : 'НЕ ЗАДАН';
};

export const getMkpoName = (data: TModelData) => {
  const name = data.mkpoClass?.name;
  const index = data.mkpoClassParent?.classIndex || 0;
  const grandIndex = data.mkpoClassGrandParent?.classIndex || 0;
  return (name) ? `${getValueWithZero(grandIndex)}-${getValueWithZero(index)} - ${name}` : 'НЕ ЗАДАНО';
};
