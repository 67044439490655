import { RegisteredModelTypes } from '@/types/modelType';

export const getTypeNameByModelType = (modelType: RegisteredModelTypes) => {
  switch (modelType) {
    case RegisteredModelTypes.TRADEMARK :
      return 'Свидетельство';
    default :
      return 'Патент';
  }
};
