<template>
  <div class="p-field-checkbox" >
    <input type="checkbox"
      :checked="!!checked"
      :value="value"
      :id="`uid${name+value}`"
      :name="name"
    />
    <label
      @click="clickCheckbox"
      :for="`uid${name+value}`"
      :class="`${ !!checked && 'ui-checked' } ${disabled && 'disabled'} ui-checkbox`"
    >
      <i class="pi pi-check"></i>
    </label>
    <label :for="`uid${name+value}`" @click="clickCheckbox">{{ label }}</label>
  </div>
</template>

<script src="./BaseCheckbox.ts" lang="ts"/>
<style src="./BaseCheckbox.scss" lang="scss" scoped/>
