import { computed, defineComponent, ref } from 'vue';
import { boolean, mixed, number, object, string } from 'yup';
import { useForm } from 'vee-validate';
import { MODEL_TYPE } from '@/dictionaries/modelData/model.type';
import BaseForm from '@/components/BaseComponents/BaseForm/BaseForm.vue';
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue';
import BaseRadioGroup from '@/components/BaseComponents/BaseRadioGroup/BaseRadioGroup.vue';
import { RegisteredModelTypes } from '@/types/modelType';
import MkpoDropdownGroup from '@/components/Forms/MkpoDropdownGroup/MkpoDropdownGroup.vue';
import BaseCheckbox from '@/components/BaseComponents/BaseCheckbox/BaseCheckbox.vue';
import { useRouter } from 'vue-router';
import { SearchHttpService } from '@/services/searchService';
import appendToastMessage from '@/utils/appendToastMessage';
import FORM_DICTIONARY from '@/dictionaries/validation/dictionary.json';
import BaseSwitcher from '@/components/BaseComponents/BaseSwitcher/BaseSwitcher.vue';
import { CompareType } from '@/store/modules/search/state';
import ROUTE_NAMES from '@/router/route.names.json';

export default defineComponent({
  name: 'FormStartSearch',

  components: {
    BaseForm,
    BaseButton,
    BaseRadioGroup,
    MkpoDropdownGroup,
    BaseCheckbox,
    BaseSwitcher
  },

  props: {
    modelId: {
      type: Number,
      required: true
    },
    modelUuid: {
      type: String,
      required: true
    }
  },

  setup(props, { emit }) {
    const isAllMkpo = ref(true);
    const useParts = ref(false);
    const { push } = useRouter();
    const searchHttpService = new SearchHttpService();

    const handleClickAllMkpo = () => {
      isAllMkpo.value = !isAllMkpo.value;
    };

    const handleClickUseParts = () => {
      useParts.value = !useParts.value;
    };

    const schema = object({
      registeredModelTypeId: number(),
      useParts: boolean(),
      compareType: string(),
      mkpoClassGrandParent: mixed(),
      mkpoClassParent: mixed(),
      mkpoClassId: mixed()
    });

    const {
      handleSubmit,
      handleReset,
      isSubmitting,
      values
    } = useForm({
      validationSchema: schema
    });

    const resetForm = () => {
      handleReset();
      isAllMkpo.value = true;
      emit('closePopup');
    };

    const changeMkpoValues = (changedClass: Array<keyof typeof values>) => {
      changedClass.forEach((item: keyof typeof values) => {
        values[item] = undefined;
      });
    };

    const submitStartSearchForm = handleSubmit(async(values) => {
      const params = {
        modelId: props.modelId,
        modelUuid: props.modelUuid,
        useParts: !!values.useParts,
        filters: [{
          mkpoClassId: undefined,
          registeredModelTypeId: values.registeredModelTypeId
        }],
        compareType: !values.compareType ? CompareType.ANALYTICAL : CompareType.NEURAL
      };

      if (values.mkpoClassId) {
        params.filters[0].mkpoClassId = values.mkpoClassId.id;
      } else if (values.mkpoClassParent) {
        params.filters[0].mkpoClassId = values.mkpoClassParent.id;
      } else if (values.mkpoClassGrandParent) {
        params.filters[0].mkpoClassId = values.mkpoClassGrandParent.id;
      } else {
        delete params.filters[0].mkpoClassId;
      }

      if (isAllMkpo.value) {
        delete params.filters[0].mkpoClassId;
      }

      try {
        // console.log(params)
        const { data } = await searchHttpService.postCompareTask(params);
        await push({ name: ROUTE_NAMES.PAGE_SEARCH, params: { id: data.id } });
      } catch (err) {
        appendToastMessage(FORM_DICTIONARY.SERVER_ERROR);
      }
    });

    const isIndustrialModel = computed(() => {
      return (values.registeredModelTypeId === RegisteredModelTypes.INDUSTRIAL_MODEL);
    });

    const modelTypeArray = Object.values(MODEL_TYPE).splice(0, 4);

    return {
      handleClickUseParts,
      handleClickAllMkpo,
      useParts,
      isAllMkpo,
      changeMkpoValues,
      isIndustrialModel,
      submitStartSearchForm,
      modelTypeArray,
      resetForm,
      values,
      isSubmitting
    };
  }
});
