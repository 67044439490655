import { onMounted, defineComponent, watch } from 'vue';
import { useField } from 'vee-validate';

export default defineComponent({
  name: 'BaseCheckbox',

  props: {
    label: {
      type: String
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: [String, Boolean, Number]
    },
    uncheckedValue: {
      type: [String, Boolean, Number]
    },
    isChecked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: ['clicked'],

  setup(props, { emit }) {
    const { checked, handleChange } = useField(props.name, undefined, {
      type: 'checkbox',
      valueProp: props.value,
      uncheckedValue: props.uncheckedValue
    });

    // TODO зарефакторить чекбоксы

    const clickCheckbox = () => {
      if (props.disabled) return;
      emit('clicked', { checked, value: props.value });
    };

    onMounted(() => {
      if (props.isChecked) handleChange(props.value);
    });

    watch(() => props.isChecked, () => {
      handleChange(props.value);
    });

    return {
      clickCheckbox,
      checked,
      handleChange
    };
  }
});
