<template>
  <BaseForm
    :handleSubmit="submitStartSearchForm"
    class="ui-start-search-form"
  >
    <template v-slot:form-content>
      <div class="strong-label">Выберите тип модели</div>
      <BaseRadioGroup
        name="registeredModelTypeId"
        :data="modelTypeArray"
      />
      <div class="strong-label">Поиск по деталям</div>
      <BaseCheckbox
        name="useParts"
        :value="true"
        :is-checked="useParts"
        :uncheckedValue="false"
        label="Искать по деталям"
        @clicked="handleClickUseParts"
      />
      <div class="strong-label">Алгоритм поиска</div>
      <BaseSwitcher
        name="compareType"
        :isChecked="true"
        label="Использовать нейросеть"
      />
      <div v-show="isIndustrialModel">
        <div class="strong-label">Параметры поиска по промышленным образцам</div>
        <BaseCheckbox
          name="allMkpo"
          value="allMkpo"
          label="Искать по всем классам"
          :is-checked="isAllMkpo"
          @clicked="handleClickAllMkpo"
        />
      </div>
      <div v-show="!isAllMkpo && isIndustrialModel">
        <MkpoDropdownGroup
          :values="values"
          @changed="changeMkpoValues"
        />
      </div>
      <div class="split-line"></div>
      <div class="p-d-flex ui-button-group">
        <BaseButton
          :disabled="isSubmitting"
          outlined
          label="Отмена"
          type="reset"
          @clicked="resetForm"
        />
        <BaseButton
          :disabled="isSubmitting"
          label="Поиск"
        />
      </div>
    </template>
  </BaseForm>
</template>

<script src="./FormStartSearch.ts" lang="ts"/>
<style src="./FormStartSearch.scss" lang="scss"/>
